/*-------------- Fonts --------------*/

@font-face {
    font-family: 'Neue Haas Unica';
    src: url(/static/media/NeueHaasUnicaW06-MediumRegular.e781c924.ttf) format("truetype");
}


/*-------------- Global --------------*/

html {
    overflow-y: scroll;
}

body {
    background-color: white;
    font-family: 'Neue Haas Unica' !important;
    color: black !important;
    line-height: 27px !important;
    font-size: 26px !important;
}

main {
    margin-top: 103px;
    padding: 10px 10px;
}

input {
    margin-bottom: 10px !important;
}

button {
    background-color: white;
    border: none;
    cursor: pointer;
}

button:focus {
    outline: none !important;
}

button u, u {
    text-decoration: none;
}

i {
    font-style: italic;
}

h1 {
    font-family: 'Neue Haas Unica' !important;
}

a:hover {
    color: black;
}

.underhover u {
    text-decoration: none;
}

.underhover u:hover {
    text-decoration: underline;
}

/* Responsive global */

.hide-lm {
    display: block;
}

@media (max-width:1280px) {
    .hide-lm {
        display: none !important;
    }
}

.show-lm {
    display: none;
}

@media (max-width:1280px) {
    .show-lm {
        display: block;
    }
}

@media (max-width:1920px) {
    main {
        margin-top: 61px;
        padding: 20px 20px;
    }

    body {
        line-height: 21px !important;
        font-size: 19px !important;
    }
}

@media (max-width:720px) {
    main {
        margin-top: 102px;
    }
}

@media (max-width:359px) {
    main {
        margin-top: 123px;
    }
}


/*-------------- Navigation --------------*/

/* Navbar */

.navbar {
    font-size: 26px;
    background-color: white;
    padding: 10px !important;
}

.navbar .container-fluid {
    padding: 0px !important;
}

.nav .navbar-text {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.nav-first button {
    padding: 0px !important;
}

.nav-last {
    padding-right: 0px !important;
}

.nav-last button {
    padding: 0px !important;
}

.navbar-header a {
    color: black;
    text-decoration: none;
    font-size: 26px;
    padding: 0px !important;
    margin-right: 32px;
}

.nav {
    -webkit-flex-direction: row !important;
            flex-direction: row !important;
}

.nav li {
    padding: 0px 16px;
}

.nav li a {
    color: black;
}

.active {
    text-decoration: underline;
}

.nav li button:hover {
    text-decoration: underline;
}

/* Basket */

.basket-button {
    background-color: white;
    border: none;
    cursor: pointer;
}

.basket-button img {
    width: 37px;
}

.basket-button b {
    font-weight: normal;
    padding-bottom: 2px;
    font-size: 21px;
}

/* Responsive navigation */

@media (max-width:1920px) {
    .navbar {
        font-size: 19px;
        line-height: 21px;
        background-color: white;
        padding: 20px !important;
    }

    .navbar-header a {
        color: black;
        text-decoration: none;
        font-size: 19px;
        line-height: 21px;
        padding: 0px !important;
        margin-right: 21px;
    }

    .nav li {
        padding: 0px 10.5px;
    }

    .basket-button img {
        width: 28px;
    }

    .basket-button b {
        font-weight: normal;
        padding-bottom: 2px;
        font-size: 14px;
    }    
}

@media (max-width:992px) {
   .container-fluid {
       padding: 0px !important;
    }

    .nav-first {
        padding-left: 0px !important;
    }

    .nav-first button {
        padding: 0px !important;
    }

    .basket-button {
        padding: 0px !important;
    }
}

@media (max-width:720px) {
    .navbar-header {
        min-width: 680px;
        padding-bottom: 20px;
    }
}

@media (max-width:480px) {
    .navbar-header {
        min-width: 0;
        padding-bottom: 20px;
    }

    .last-brand {
        margin-right: 0px !important;
    }
}

@media (max-width:374px) {
    .nav li {
        padding: 0px 7.5px;
    }
}

/*-------------- Home (Projects) --------------*/

#Home {
    margin-top: 0px;
    padding-top: 0px;
    margin-bottom: 100px;
}

#Home h1 {
    font-weight: normal;
    font-family: 'Neue Haas Unica' !important;
    font-style: italic;
    font-size: 26px;
}

#Home button:hover {
    text-decoration: underline;
}

.home-project {
    padding-top: 166px;
}

.project-slideshow {
    max-width: 63.15%;
}

.project-caption {
    z-index: 100;
    max-width: 63.15%;
}

.project-caption p, .project-caption h1 {
    margin-bottom: 0px;
    margin-top: 7px;
    display: inline;
}

.project-caption button {
    display: inline;
}

@media (max-width:1920px) {
    #Home h1 {
        font-size: 19px;
    }

    .project-slideshow {
        max-width: 60%;
    }

    .home-project {
        padding-top: 121px;
    }

    .project-caption {
        max-width: 60%;
    }
}

@media (max-width:992px) {
    #Home {
        margin-top: 61px;
    }

    .home-project {
        padding-top: 60px;
    }

    #Home .home-project:first-of-type {
        padding-top: 30px !important;
    }

    .project-slideshow {
        max-width: 100%;
    }

    .project-caption {
        max-width: 100%;
    }

    .project-caption p, .project-caption h1 {
        margin-top: 0px;
    }
}

@media (max-width:720px) {
    #Home {
        margin-top: 102px;
    }
}

@media (max-width:359px) {
    #Home {
        margin-top: 123px;
    }
}


/*-------------- Index --------------*/

#Index {
    margin-top: 23.5%;
}

.index-margin-bot {
    height: 10px;
    width: 100%;
    background-color: white;
    position: absolute;
    bottom: 0px;
}

.index-cover {
    padding: 1% 1%;
}

.index-project h1, .index-project p {
    color: black;
    font-family: 'Neue Haas Unica';
    font-size: 26px;
    line-height: 27px;
    margin: 0px 0px;
}

.index-title {
    margin: 1%;
}

.index-project img {
    width: 100%;
    height: auto;
}

.index-project img:hover {
    opacity: 0.73;
}

/* Responsive index */

@media (max-width:1920px) {
    #Index {
        margin-top: 23%;
    }

    .index-project h1, .index-project p {
        font-size: 19px;
        line-height: 21px;
    }

    .index-margin-bot {
        height: 20px;
    }
}

@media (max-width:992px) {
    #Index {
        margin-top: 61px; 
    }
}

@media (max-width:720px) {
    #Index {
        margin-top: 102px;
    }
}


/*-------------- About --------------*/

#About section {
    max-width: 63.15%;
}

#About article {
    padding-bottom: 64px;
}

#About article h1 {
    margin-top: 0px;
    margin-bottom: 32px;
    font-size: 26px;
    line-height: 27px;
    font-family: 'Neue Haas Unica' !important;
    text-decoration: underline;
}

#About article p {
    font-size: 26px;
    font-family: 'Neue Haas Unica' !important;
    text-decoration: none;
    padding-bottom: 0px;
    margin-bottom: 0px;
}

#About article h2 {
    margin-top: 0px;
    padding-top: 0px;
    margin-bottom: 0px;
    padding-bottom: 0px;
    font-size: 26px;
    line-height: 27px;
    font-family: 'Neue Haas Unica' !important;
    text-decoration: underline;
}

#Credits p {
    margin-bottom: 64px !important;
}

#About a {
    margin-top: 0px;
    padding-top: 0px;
    margin-bottom: 0px;
    padding-bottom: 0px;
    font-size: 26px;
    line-height: 27px;
    font-family: 'Neue Haas Unica' !important;
    text-decoration: none;
}

#About a:hover {
    color: black;
}


@media (max-width:1920px) {
    #About section {
        max-width: 60%;
    }
    
    #About article {
        padding-bottom: 42px;
    }

    #About article h1 {
        margin-top: 0px;
        margin-bottom: 21px;
        font-size: 19px;
        line-height: 21px;
        font-family: 'Neue Haas Unica' !important;
        text-decoration: underline;
    }
    
    #About article p {
        font-size: 19px;
        line-height: 21px;
    }
    
    #About article h2 {
        font-size: 19px;
        line-height: 21px;
    }
    
    #Credits p {
        margin-bottom: 42px !important;
    }
    
    #About a {
        font-size: 19px;
        line-height: 21px;
    }
}

@media (max-width:992px) {
    #About section {
        max-width: 100%;
    }
}


/*-------------- Shop (Items) --------------*/

#Shop {
    margin-top: 13%;
}

/* Responsive shop */

@media (max-width:992px) {
    
    #Shop {
        margin-top: 61px; 
    }

    #Shop .index-project {
        float: left !important;
    }
}

@media (max-width:720px) {
    #Shop {
        margin-top: 102px;
    }
}


/*-------------- Item --------------*/

#Item {
    margin-top: 160px;
}

.item-slideshow {
    max-width: 63.5%;
}

.item-description {
    padding-left: 30px;
    max-width: 562px;
}

.item-description h1 {
    margin: 0px;
    text-decoration: underline;
    font-size: 26px;
    line-height: 27px;
    font-family: 'Neue Haas Unica' !important;
}

.item-description p {
    margin-bottom: 32px;
}

.item-description button {
    padding: 0px;
}

.item-description button:hover {
    text-decoration: underline;
}

.item-description .underhover a {
    cursor: pointer;
}

.color-boxes {
    margin-bottom: 32px;
}

.color-boxes p {
    margin-bottom: 8px;
}

.color-box {
    width: 54px;
    height: 54px;
    margin-right: 8px;
}

.color-box:hover {
    cursor: pointer;
}

@media (max-width:1920px) {
    #Item {
        margin-top: 121px;
    }

    .item-slideshow {
        max-width: 65%;
    }

    .item-description {
        padding-left: 20px;
        max-width: 26%;
    }

    .item-description h1 {
        font-size: 19px;
        line-height: 21px;
    }

    .item-description p {
        margin-bottom: 21px;
    }

    .color-boxes {
        margin-bottom: 21px;
    }

    .color-boxes p {
        margin-bottom: 5px;
    }

    .color-box {
        width: 24px;
        height: 24px;
        margin-right: 6px;
    }    
}

@media (max-width:992px) {
    #Item {
        margin-top: 61px;
    }

    .item-slideshow {
        max-width: 100%;
    }

    .item-description {
        padding-left: 0px;
        max-width: 100%;
    }

    .item-description p {
        max-width: 100%;
    }

    .item-price {
        width: 40%;
        margin-bottom: 30px;
    }

    .color-boxes {
        width: 60%;
        margin-bottom: 30px;
    }

    .color-boxes p {
        margin-bottom: 15px;
    }

    .color-box {
        margin-bottom: -4px;
        width: 28px;
        height: 28px;
    }
}

@media (max-width:720px) {
    #Item {
        margin-top: 102px;
    }
}

@media (max-width:359px) {
    #Item {
        margin-top: 123px;
    }
}


/*-------------- Slideshow --------------*/

.prev {
    cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAfCAYAAACCox+xAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAA3XAAAN1wFCKJt4AAAAB3RJTUUH4goMETUVRTZYKgAAAeZJREFUWMPFlzFoFFEQhr/ZvRMRLisWSm7zchsERbBRsAhqI9iISDrtBBFTKApWgtipYCEoiiKksxDrQwV7Cy0UlIDVGTbPI2ght1uZ3O5YeDnkvFwEufd+eLDFFh87386bgc0j09PxSUAYY4LNXjAmPqtK05j4uTeQmZmdu4C7ACI0vYF0u9X7wA6gmaZfn3oBMaY+B5wWIS8KvcSYMxQkSZLtII96rt5ot9upF5CiWLsNTAJv0tQ+wEGCv0syeRSYB36GoZ4HSucgSZJshWABCETkztJS+zOOEgyU5DqwB3SxVotu4TD9btlo1A+UpbwDRFUOW2vfugRZ/yJhWcoToALy2DVEH8SY+DJwCOTL6uraNTxEjDG7ofwIbFOVE9baV55A4tfAcUBd/arDUhGhotoXN/QGUpZyRUTf/wYpZpeXVz74AAmzLPsWRdEEcEQk2N/p5Au9Ejk9AUC1uuUm6Ioqs1NT9XNeZP3j2j8D8gz4LhLuTdP0h9PSrD9kWb4YRdExYB/oRJblL3x0VgBV5SLQBS40GvWDvkCw1n4CuQeEqvJw3JP7yHnEl7iywbzqXNyhnXRA3FqW5S+9zKwD4s67EHfDdcK1uCMXLJfijgRptVod4GrP6zl8x5j6qX9Z2P8nvwCersm8Aq10ngAAAABJRU5ErkJggg==) 16 16, pointer;
}

.next {
    cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAfCAYAAACCox+xAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAA3XAAAN1wFCKJt4AAAAB3RJTUUH4goMETUhZIKsnwAAAchJREFUWMPF17FrFEEUx/Hv29k7C1HTnezNLPEq5VDQxkIEK0EwgpVYWIugKFhp0gqpFESLiP4BdkljY2FhYSFoCitBwt3iBRUU98DG7D6LbAplE9Psvl81C1N8WH7zZheaTRRCcmFXG5tUeN9fAVkJIblsCgFdrhb3B4PBgZ12uiYZeT5dnZnZfxbkSFmWcZ7nL43eCCqi14EC9Jb3/qgVhNFo8g5YAmIRHgNiAgEQcQvAN9DTISSXzCDj8fiHqt6pHh/UFdfRUv5X3Ij2smNx24RsFfdJXXFbhVTFna8rbuuQ7YrrMEhdcQWILTAhHDwO7g2gqnJCQugrxhHhVbx5nMwSAaLKhlgJvPfnRPQF8AuiY5EFotfr7a3mCMBClmWfTCDdbmcR9BDwNss+PzSZI977k6DXgI0o0qtbHW0VMhwOuyLls2p+LY5Gk/cmk3U6/TkPMgQ+Ote599cRbgsxO5scLgpZBTpQnsmy9dcWt29UFPIU2AMs/YtoDZKm/gZwClh3rnO3dro2jUiSJI1j+aDKPtCLWTZZNvlmdU4ebSJ4vh2icUia9q8Ac8D3OP590+yXU5W5anl7be3rF9MbPk3753fTxT+4eqRp/FgOmQAAAABJRU5ErkJggg==) 16 16, pointer;
}

.left {
    cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAfCAYAAACCox+xAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAA3XAAAN1wFCKJt4AAAAB3RJTUUH4goMETUVRTZYKgAAAeZJREFUWMPFlzFoFFEQhr/ZvRMRLisWSm7zchsERbBRsAhqI9iISDrtBBFTKApWgtipYCEoiiKksxDrQwV7Cy0UlIDVGTbPI2ght1uZ3O5YeDnkvFwEufd+eLDFFh87386bgc0j09PxSUAYY4LNXjAmPqtK05j4uTeQmZmdu4C7ACI0vYF0u9X7wA6gmaZfn3oBMaY+B5wWIS8KvcSYMxQkSZLtII96rt5ot9upF5CiWLsNTAJv0tQ+wEGCv0syeRSYB36GoZ4HSucgSZJshWABCETkztJS+zOOEgyU5DqwB3SxVotu4TD9btlo1A+UpbwDRFUOW2vfugRZ/yJhWcoToALy2DVEH8SY+DJwCOTL6uraNTxEjDG7ofwIbFOVE9baV55A4tfAcUBd/arDUhGhotoXN/QGUpZyRUTf/wYpZpeXVz74AAmzLPsWRdEEcEQk2N/p5Au9Ejk9AUC1uuUm6Ioqs1NT9XNeZP3j2j8D8gz4LhLuTdP0h9PSrD9kWb4YRdExYB/oRJblL3x0VgBV5SLQBS40GvWDvkCw1n4CuQeEqvJw3JP7yHnEl7iywbzqXNyhnXRA3FqW5S+9zKwD4s67EHfDdcK1uCMXLJfijgRptVod4GrP6zl8x5j6qX9Z2P8nvwCersm8Aq10ngAAAABJRU5ErkJggg==) 16 16, pointer;
}

.right {
    cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAfCAYAAACCox+xAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAA3XAAAN1wFCKJt4AAAAB3RJTUUH4goMETUhZIKsnwAAAchJREFUWMPF17FrFEEUx/Hv29k7C1HTnezNLPEq5VDQxkIEK0EwgpVYWIugKFhp0gqpFESLiP4BdkljY2FhYSFoCitBwt3iBRUU98DG7D6LbAplE9Psvl81C1N8WH7zZheaTRRCcmFXG5tUeN9fAVkJIblsCgFdrhb3B4PBgZ12uiYZeT5dnZnZfxbkSFmWcZ7nL43eCCqi14EC9Jb3/qgVhNFo8g5YAmIRHgNiAgEQcQvAN9DTISSXzCDj8fiHqt6pHh/UFdfRUv5X3Ij2smNx24RsFfdJXXFbhVTFna8rbuuQ7YrrMEhdcQWILTAhHDwO7g2gqnJCQugrxhHhVbx5nMwSAaLKhlgJvPfnRPQF8AuiY5EFotfr7a3mCMBClmWfTCDdbmcR9BDwNss+PzSZI977k6DXgI0o0qtbHW0VMhwOuyLls2p+LY5Gk/cmk3U6/TkPMgQ+Ote599cRbgsxO5scLgpZBTpQnsmy9dcWt29UFPIU2AMs/YtoDZKm/gZwClh3rnO3dro2jUiSJI1j+aDKPtCLWTZZNvlmdU4ebSJ4vh2icUia9q8Ac8D3OP590+yXU5W5anl7be3rF9MbPk3753fTxT+4eqRp/FgOmQAAAABJRU5ErkJggg==) 16 16, pointer;
}

.animate-left {
    position: relative;
    -webkit-animation: animateleft 1s;
            animation: animateleft 1s
}

@-webkit-keyframes animateleft {
    from {
        left: -300px;
        opacity: 1
    } to {
        left: 0;
        opacity: 1
    }
}

@keyframes animateleft {
    from {
        left: -300px;
        opacity: 1
    } to {
        left: 0;
        opacity: 1
    }
}

.animate-right {
    position: relative;
    -webkit-animation: animateright 1s;
            animation: animateright 1s
}

@-webkit-keyframes animateright {
    from {
        right: -300px;
        opacity: 1
    } to {
        right: 0;
        opacity: 1
    }
}

@keyframes animateright {
    from {
        right: -300px;
        opacity: 1
    } to {
        right: 0;
        opacity: 1
    }
}

.slideshow {
    overflow: hidden;
    max-height: 583px;
}

.slideshow-inner {
    overflow: hidden;
}

.slideshow-inner {
    position: relative;
    display: inline-block;
    padding: 0;
    margin: 0;
}

.slide img {
    height: 100%;
    width: auto;
}

.item-slideshow .slide img {
    float: right;
}

@media (max-width:992px) {
    .item-slideshow .slide img {
        width: 100%;
        /* height: auto; */
    }    
}


/*-------------- Shopping cart (Payements - Step 1) --------------*/

/* Table */

#Basket {
    margin-bottom: 60px;
    width: 70%;
}

/* Headings */

#Headings {
    border-bottom: 2px solid black;
}

#Headings th {
    font-weight: normal;    
}

.product {
    padding-left: 0px !important;
}

.quantity {
    text-align: center !important;
}

/* Item row */

.basket-row td {
    vertical-align: middle !important;
    width: 33%;
}

.basket-product {
    position: relative;
}

.basket-item {
    text-align: center;
    margin-bottom: 28px;
    margin-top: 28px;
    padding-left: 104px;
}

.remove-button {
    position: absolute !important;
    top: 40%;
    padding: 0;
    padding-left: 16px;
    color: black;
    cursor: pointer;
    border: 0px;
    z-index: 100;
}

.remove-button img {
    width: 32px;
    height: 32px;
}

.basket-item img {
    width: 342px;
    height: 218px;
}

.basket-description {
    font-size: 26px;
    line-height: 27px;
    font-family: 'Neue Haas Unica' !important;
    text-decoration: underline;
    margin-top: 0px;
    margin-bottom: 0px;
    text-align: center;
}

.basket-quantity input {
    text-align: center;
    width: 35px;
    height: 35px;
    -moz-appearance: textfield;
}

.basket-price p {
    text-align: right;
}

/* Totals */

#Basket-Total {
    border-top: 2px solid black;
}

.basket-quantity {
    text-align: center !important;
}

/* Next Step Button */

#NextStep-Button {
    width: 50%;
    float: right;
    margin-top: 48px;
}

.order-button:hover {
    text-decoration: underline;
}

.disabled {
    color: black !important;
    cursor: default !important;
}

.disabled:hover {
    text-decoration: none !important;
}

/* Responsive shoping cart */

@media (max-width:1920px) {
    .basket-item {
        text-align: center;
        margin-bottom: 28px;
        margin-top: 28px;
        padding-left: 51px;
    }

    .basket-item img {
        width: 319px;
        height: auto;
    }

    .remove-button {
        top: 45%;
        padding-left: 0px;
    }

    .remove-button img {
        width: 19px;
        height: 19px;
    }

    .basket-description {
        font-size: 19px;
        line-height: 21px;
    }
}

@media (max-width:992px) {
    #Basket {
        width: 100%;
    }

    .remove-button img {
        width: 28px;
        height: 28px;
    }

    .basket-product {
        padding: 15px 0px !important;
    }

    .basket-item {
        margin: 0px;
        padding: 0px;
    }

    .basket-item .item-sticker {
        width: 310px;
        height: auto;
    }

    .remove-button {
        background: transparent;
        padding: 5px;
    }

    .basket-description {
        text-align: left;
    }
}

@media (max-width:480px) {
    .remove-button img {
        width: 19px;
        height: 19px;
    }
}


/*-------------- Order (Payements - Step 2) --------------*/

#Order {
    width: 70%;
    padding: 20px 10px;
}

.billing-form {
    padding-left: 0px !important;
}

.order-heading {
    border-bottom: 2px solid black;
}

.order-bar {
    border-bottom: 2px solid black;
}

.order-bar label {
    margin-top: 14.5px;
    margin-bottom: 15px;
}

.order-bar input, .order-bar select {
    background-color: white;
    margin: 6.5px 0px !important;
    border: 0px;
    height: 45.5px;
    padding: 0px !important;
}

.order-bar select, .order-other-address select {
    max-width: 50%;
}

.border-bottom {
    border-bottom: 2px solid black !important;
}

.order-other-address input, .order-other-address select {
    background-color: white;
    border: none;
    cursor: pointer;
    padding: 15px 0px !important;
    margin: 0px !important;
}

#Other-Address {
    margin-top: 15px;
}

.order-item {
    border-bottom: 2px solid black !important;
    margin-top: 15px;
    height: 165.5px;
}

.order-item-fig {
    text-align: center;
    width: 208px;
    height: 133px;
}

.order-item-fig h1 {
    font-size: 19px;
    line-height: 19px;
    margin: 0px;
    text-decoration: underline !important;
    text-align: center;
}

.order-item-detail {
    text-align: right;
}

.order-item-price {
    margin-top: 91px;
}

.total-bar {
    border-bottom: 2px solid black !important;
}

.total-bar p {
    margin: 15px 0px;
}

#Delivery-Time {
    margin-top: 10.25px !important;
}

#Delivery-Time p {
    font-size: 19px;
    line-height: 19px;
    margin: 0px;
}

.margin-bot {
    margin-bottom: 10.25px !important;
}

.form-row {
    margin: 0px !important;
}

.payment-heading p label {
    margin-bottom: 0px !important;
}

.card-input {
    padding: 10.75px 0px !important;
}

.order-cgv {
    margin-top: 10.25px !important;
    font-size: 19px;
    line-height: 19px;
    width: 100%;
}

.back-button {
    margin-left: 10px;
    margin-bottom: 10px;
}

#card-errors {
    font-size: 12px !important;
}

/* Responsive order */

@media (max-width:1920px) {

    .billing-form {
        padding-left: 10px !important;
    }

    .order-item {
        height: 165.5px;
    }
    
    .order-bar input, .order-bar select {
        height: 45.5px;
    }

    .order-item-title {
        font-size: 19px;
        line-height: 21px;
        margin: 0px;
        text-decoration: underline !important;
        text-align: left;
    }

    .order-bar label {
        margin-top: 18px;
        margin-bottom: 18.75px;
    }

    #Order {
        width: 85%;
    }

    .order-item-fig {
        text-align: left;
        width: 152px;
        height: auto;
    }

    .order-item-amount {
        vertical-align: middle;
        margin-top: 32.5px;
    }

    #Delivery-Time {
        margin-top: 15px !important;
    }

    #Delivery-Time p {
        font-size: 14px;
        line-height: 14px;
        margin: 0px;
    }

    .order-cgv {
        margin-top: 15px !important;
        font-size: 14px;
        line-height: 14px;
        width: 100%;
    }

    .card-input {
        padding: 18.5px 0px !important;
    }

    .margin-bot {
        margin-bottom: 15.5px !important;
    }

    .total-bar p {
        margin: 18.75px 0px;
    }
}

@media (max-width:992px) {
    #Order {
        width: 100%;
    }

    .billing-form {
        padding-right: 10px !important;
    }

    .order-recap {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }

    .order-item {
        height: 176.5px;
    }

    .order-item-amount {
        vertical-align: middle;
        margin-top: 104.5px;
    }

    .order-item-fig {
        text-align: left;
        width: 198px;
        height: auto;
    }
}


/*-------------- Receipt (Payements - Step 3) --------------*/

#Receipt {
    width: 70%;
    padding: 20px 10px;
}

.order-bar p {
    line-height: 40px;
    overflow: auto;
    overflow-y: hidden;
    -ms-overflow-y: hidden;
    overflow-x: hidden;
    -ms-overflow-x: hidden;
    margin-top: 9px;
    margin-bottom: 9.5px;
}

/* Responsive order */

@media (max-width:1920px) {
    #Receipt {
        width: 85%;
    }
}

@media (max-width:992px) {
    #Receipt {
        width: 100%;
    }
}


/*-------------- Cookies --------------*/

.cookies-alert {
    padding: 10px;
    background-color: transparent;
    overflow: visible;
    width: 100% !important;
}

.cookies-alert-inner {
    background-color: white;
    padding: 10px;
    border: 2px solid black;
    width: 100%;
}

.cookies-alert p {
    font-size: 26px;
    line-height: 27px;
    margin: 0px;
    max-width: 90%;
    vertical-align: middle;
}

.cookies-alert button {
    position: absolute;
    top: 30%;
    right: 20px;
    font-size: 29px;
    line-height: 29px;
}

@media (max-width:1920px) {
    .cookies-alert p {
        font-size: 14px;
        line-height: 14px;
        padding-bottom: 4px;
    }

    .cookies-alert button {
        top: 35%;
        right: 20px;
        font-size: 16px;
        line-height: 16px;
    }   
}


/*-------------- Legal Notices --------------*/

#Legal {
    font-size: 26px;
    line-height: 27px;
    font-family: 'Neue Haas Unica' !important;
    margin: 64px 0px;
}

#Legal h1 {
    font-size: 26px;
    line-height: 27px;
    font-family: 'Neue Haas Unica' !important;
    text-decoration: underline;
    margin-top: 0px;
    margin-bottom: 32px;
}

#Legal h2 {
    font-size: 26px;
    line-height: 27px;
    font-family: 'Neue Haas Unica' !important;
    margin-top: 0px;
    margin-bottom: 32px;
}

#Legal p {
    margin-top: 0px;
    margin-bottom: 32px;
}

/* Responsive legal notices */

@media (max-width:1920px) {
    #Legal {
        font-size: 19px;
        line-height: 21px;
        margin: 42px 0px;
    }
    
    #Legal h1 {
        font-size: 19px;
        line-height: 21px;
        margin-bottom: 21px;
    }
    
    #Legal h2 {
        font-size: 19px;
        line-height: 21px;
        margin-bottom: 21px;
    }
    
    #Legal p {
        margin-bottom: 21px;
    }
}


/*-------------- Backoffice (   ) --------------*/

#Admin {
    position: relative;
}

.home-back {
    font-family: 'Neue Haas Unica';
    text-align: center;
    font-weight: normal; 
    text-decoration: none;
    font-size: 26px;
    line-height: 27px;
    margin: auto;
    padding-bottom: 32px;
}

.nav-back {
    margin-bottom: 32px !important;
    margin: auto;
}

.disconnect {
    margin-right: 32px;
    position: absolute;
    top: 0px;
    right: 0px;
}

.helvetica {
    font-weight: normal;
    font-size: 19px;
    line-height: 21px;
    font-family: 'Neue Haas Unica';
    padding-right: 15px;
}

.noto {
    font-family: 'Neue Haas Unica';
    font-weight: normal;
    font-size: 26px;;
}

.text-back {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
}

.text-back p, .text-back input {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.image-back {
    max-width: 200px;
    max-height: 200px;
}

.form-back {
    width: 100%;
}

#Admin p {
    margin-bottom: 5px;
}

/* Responsive backoffice */

@media (max-width:1920px) {
    .home-back {
        font-size: 19px;
        line-height: 21px;
    }
    
    .helvetica {
        font-size: 14px;
        line-height: 18px;
    }
    
    .noto {
        font-size: 19px;
        line-height: 21px;
    }
}
/* W3.CSS 4.10 February 2018 by Jan Egil and Borge Refsnes */
html{box-sizing:border-box}*,*:before,*:after{box-sizing:inherit}
/* Extract from normalize.css by Nicolas Gallagher and Jonathan Neal git.io/normalize */
html{-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%}body{margin:0}
article,aside,details,figcaption,figure,footer,header,main,menu,nav,section,summary{display:block}
audio,canvas,progress,video{display:inline-block}progress{vertical-align:baseline}
audio:not([controls]){display:none;height:0}[hidden],template{display:none}
a{background-color:transparent;-webkit-text-decoration-skip:objects}
a:active,a:hover{outline-width:0}abbr[title]{border-bottom:none;text-decoration:underline;-webkit-text-decoration:underline dotted;text-decoration:underline dotted}
dfn{font-style:italic}mark{background:#ff0;color:#000}
small{font-size:80%}sub,sup{font-size:75%;line-height:0;position:relative;vertical-align:baseline}
sub{bottom:-0.25em}sup{top:-0.5em}figure{margin:1em 40px}img{border-style:none}svg:not(:root){overflow:hidden}
code,kbd,pre,samp{font-family:monospace,monospace;font-size:1em}hr{box-sizing:content-box;height:0;overflow:visible}
button,input,select,textarea{font:inherit;margin:0}optgroup{font-weight:bold}
button,input{overflow:visible}button,select{text-transform:none}
button,html [type=button],[type=reset],[type=submit]{-webkit-appearance:button}
button::-moz-focus-inner, [type=button]::-moz-focus-inner, [type=reset]::-moz-focus-inner, [type=submit]::-moz-focus-inner{border-style:none;padding:0}
button:-moz-focusring, [type=button]:-moz-focusring, [type=reset]:-moz-focusring, [type=submit]:-moz-focusring{outline:1px dotted ButtonText}
fieldset{border:1px solid #c0c0c0;margin:0 2px;padding:.35em .625em .75em}
legend{color:inherit;display:table;max-width:100%;padding:0;white-space:normal}textarea{overflow:auto}
[type=checkbox],[type=radio]{padding:0}
[type=number]::-webkit-inner-spin-button,[type=number]::-webkit-outer-spin-button{height:auto}
[type=search]{-webkit-appearance:textfield;outline-offset:-2px}
[type=search]::-webkit-search-cancel-button,[type=search]::-webkit-search-decoration{-webkit-appearance:none}
::-webkit-input-placeholder{color:inherit;opacity:0.54}
::-webkit-file-upload-button{-webkit-appearance:button;font:inherit}
/* End extract */
html,body{font-family:Verdana,sans-serif;font-size:15px;line-height:1.5}html{overflow-x:hidden}
h1{font-size:36px}h2{font-size:30px}h3{font-size:24px}h4{font-size:20px}h5{font-size:18px}h6{font-size:16px}.w3-serif{font-family:serif}
h1,h2,h3,h4,h5,h6{font-family:"Segoe UI",Arial,sans-serif;font-weight:400;margin:10px 0}.w3-wide{letter-spacing:4px}
hr{border:0;border-top:1px solid #eee;margin:20px 0}
.w3-image{max-width:100%;height:auto}img{vertical-align:middle}a{color:inherit}
.w3-table,.w3-table-all{border-collapse:collapse;border-spacing:0;width:100%;display:table}.w3-table-all{border:1px solid #e4e4e4}
.w3-bordered tr,.w3-table-all tr{border-bottom:1px solid #ddd}.w3-striped tbody tr:nth-child(even){background-color:#f1f1f1}
.w3-table-all tr:nth-child(odd){background-color:#fff}.w3-table-all tr:nth-child(even){background-color:#f1f1f1}
.w3-hoverable tbody tr:hover,.w3-ul.w3-hoverable li:hover{background-color:#e4e4e4}.w3-centered tr th,.w3-centered tr td{text-align:center}
.w3-table td,.w3-table th,.w3-table-all td,.w3-table-all th{padding:8px 8px;display:table-cell;text-align:left;vertical-align:top}
.w3-table th:first-child,.w3-table td:first-child,.w3-table-all th:first-child,.w3-table-all td:first-child{padding-left:16px}
.w3-btn,.w3-button{border:none;display:inline-block;padding:8px 16px;vertical-align:middle;overflow:hidden;text-decoration:none;color:inherit;background-color:inherit;text-align:center;cursor:pointer;white-space:nowrap}
.w3-btn:hover{box-shadow:0 8px 16px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19)}
.w3-btn,.w3-button{-webkit-touch-callout:none;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}   
.w3-disabled,.w3-btn:disabled,.w3-button:disabled{cursor:not-allowed;opacity:0.3}.w3-disabled *,:disabled *{pointer-events:none}
.w3-btn.w3-disabled:hover,.w3-btn:disabled:hover{box-shadow:none}
.w3-badge,.w3-tag{background-color:#000;color:#fff;display:inline-block;padding-left:8px;padding-right:8px;text-align:center}.w3-badge{border-radius:50%}
.w3-ul{list-style-type:none;padding:0;margin:0}.w3-ul li{padding:8px 16px;border-bottom:1px solid #ddd}.w3-ul li:last-child{border-bottom:none}
.w3-tooltip,.w3-display-container{position:relative}.w3-tooltip .w3-text{display:none}.w3-tooltip:hover .w3-text{display:inline-block}
.w3-ripple:active{opacity:0.5}.w3-ripple{transition:opacity 0s}
.w3-input{padding:8px;display:block;border:none;border-bottom:1px solid #e4e4e4;width:100%}
.w3-select{padding:9px 0;width:100%;border:none;border-bottom:1px solid #e4e4e4}
.w3-dropdown-click,.w3-dropdown-hover{position:relative;display:inline-block;cursor:pointer}
.w3-dropdown-hover:hover .w3-dropdown-content{display:block}
.w3-dropdown-hover:first-child,.w3-dropdown-click:hover{background-color:#e4e4e4;color:#000}
.w3-dropdown-hover:hover > .w3-button:first-child,.w3-dropdown-click:hover > .w3-button:first-child{background-color:#e4e4e4;color:#000}
.w3-dropdown-content{cursor:auto;color:#000;background-color:#fff;display:none;position:absolute;min-width:160px;margin:0;padding:0;z-index:1}
.w3-check,.w3-radio{width:24px;height:24px;position:relative;top:6px}
.w3-sidebar{height:100%;width:200px;background-color:#fff;position:fixed!important;z-index:1;overflow:auto}
.w3-bar-block .w3-dropdown-hover,.w3-bar-block .w3-dropdown-click{width:100%}
.w3-bar-block .w3-dropdown-hover .w3-dropdown-content,.w3-bar-block .w3-dropdown-click .w3-dropdown-content{min-width:100%}
.w3-bar-block .w3-dropdown-hover .w3-button,.w3-bar-block .w3-dropdown-click .w3-button{width:100%;text-align:left;padding:8px 16px}
.w3-main,#main{transition:margin-left .4s}
.w3-modal{z-index:3;display:none;padding-top:100px;position:fixed;left:0;top:0;width:100%;height:100%;overflow:auto;background-color:rgb(0,0,0);background-color:rgba(0,0,0,0.4)}
.w3-modal-content{margin:auto;background-color:#fff;position:relative;padding:0;outline:0;width:600px}
.w3-bar{width:100%;overflow:hidden}.w3-center .w3-bar{display:inline-block;width:auto}
.w3-bar .w3-bar-item{padding:8px 16px;float:left;width:auto;border:none;display:block;outline:0}
.w3-bar .w3-dropdown-hover,.w3-bar .w3-dropdown-click{position:static;float:left}
.w3-bar .w3-button{white-space:normal}
.w3-bar-block .w3-bar-item{width:100%;display:block;padding:8px 16px;text-align:left;border:none;white-space:normal;float:none;outline:0}
.w3-bar-block.w3-center .w3-bar-item{text-align:center}.w3-block{display:block;width:100%}
.w3-responsive{display:block;overflow-x:auto}
.w3-container:after,.w3-container:before,.w3-panel:after,.w3-panel:before,.w3-row:after,.w3-row:before,.w3-row-padding:after,.w3-row-padding:before,
.w3-cell-row:before,.w3-cell-row:after,.w3-clear:after,.w3-clear:before,.w3-bar:before,.w3-bar:after{content:"";display:table;clear:both}
.w3-col,.w3-half,.w3-third,.w3-twothird,.w3-threequarter,.w3-quarter{float:left;width:100%}
.w3-col.s1{width:8.33333%}.w3-col.s2{width:16.66666%}.w3-col.s3{width:24.99999%}.w3-col.s4{width:33.33333%}
.w3-col.s5{width:41.66666%}.w3-col.s6{width:49.99999%}.w3-col.s7{width:58.33333%}.w3-col.s8{width:66.66666%}
.w3-col.s9{width:74.99999%}.w3-col.s10{width:83.33333%}.w3-col.s11{width:91.66666%}.w3-col.s12{width:99.99999%}
@media (min-width:601px){.w3-col.m1{width:8.33333%}.w3-col.m2{width:16.66666%}.w3-col.m3,.w3-quarter{width:24.99999%}.w3-col.m4,.w3-third{width:33.33333%}
.w3-col.m5{width:41.66666%}.w3-col.m6,.w3-half{width:49.99999%}.w3-col.m7{width:58.33333%}.w3-col.m8,.w3-twothird{width:66.66666%}
.w3-col.m9,.w3-threequarter{width:74.99999%}.w3-col.m10{width:83.33333%}.w3-col.m11{width:91.66666%}.w3-col.m12{width:99.99999%}}
@media (min-width:993px){.w3-col.l1{width:8.33333%}.w3-col.l2{width:16.66666%}.w3-col.l3{width:24.99999%}.w3-col.l4{width:33.33333%}
.w3-col.l5{width:41.66666%}.w3-col.l6{width:49.99999%}.w3-col.l7{width:58.33333%}.w3-col.l8{width:66.66666%}
.w3-col.l9{width:74.99999%}.w3-col.l10{width:83.33333%}.w3-col.l11{width:91.66666%}.w3-col.l12{width:99.99999%}}
@media (min-width:1921px){.w3-col.xl1{width:8.33333%}.w3-col.xl2{width:16.66666%}.w3-col.xl3{width:24.99999%}.w3-col.xl4{width:33.33333%}
.w3-col.xl5{width:41.66666%}.w3-col.xl6{width:49.99999%}.w3-col.xl7{width:58.33333%}.w3-col.xl8{width:66.66666%}
.w3-col.xl9{width:74.99999%}.w3-col.xl10{width:83.33333%}.w3-col.xl11{width:91.66666%}.w3-col.xl12{width:99.99999%}}
.w3-content{max-width:980px;margin:auto}.w3-rest{overflow:hidden}
.w3-cell-row{display:table;width:100%}.w3-cell{display:table-cell}
.w3-cell-top{vertical-align:top}.w3-cell-middle{vertical-align:middle}.w3-cell-bottom{vertical-align:bottom}
.w3-hide{display:none!important}.w3-show-block,.w3-show{display:block!important}.w3-show-inline-block{display:inline-block!important}
@media (max-width:600px){.w3-modal-content{margin:0 10px;width:auto!important}.w3-modal{padding-top:30px}
.w3-dropdown-hover.w3-mobile .w3-dropdown-content,.w3-dropdown-click.w3-mobile .w3-dropdown-content{position:relative}	
.w3-hide-small{display:none!important}.w3-mobile{display:block;width:100%!important}.w3-bar-item.w3-mobile,.w3-dropdown-hover.w3-mobile,.w3-dropdown-click.w3-mobile{text-align:center}
.w3-dropdown-hover.w3-mobile,.w3-dropdown-hover.w3-mobile .w3-btn,.w3-dropdown-hover.w3-mobile .w3-button,.w3-dropdown-click.w3-mobile,.w3-dropdown-click.w3-mobile .w3-btn,.w3-dropdown-click.w3-mobile .w3-button{width:100%}}
@media (max-width:768px){.w3-modal-content{width:500px}.w3-modal{padding-top:50px}}
@media (min-width:993px){.w3-modal-content{width:900px}.w3-sidebar.w3-collapse{display:block!important}}
@media (max-width:992px) and (min-width:601px){.w3-hide-medium{display:none!important}}
@media (max-width:1920px) and (min-width:993px){.w3-hide-large{display:none!important}}
@media (min-width:1921px){.w3-hide-xlarge{display:none!important}}
@media (max-width:992px){.w3-sidebar.w3-collapse{display:none}.w3-main{margin-left:0!important;margin-right:0!important}}
.w3-top,.w3-bottom{position:fixed;width:100%;z-index:1}.w3-top{top:0}.w3-bottom{bottom:0}
.w3-overlay{position:fixed;display:none;width:100%;height:100%;top:0;left:0;right:0;bottom:0;background-color:rgba(0,0,0,0.5);z-index:2}
.w3-display-topleft{position:absolute;left:0;top:0}.w3-display-topright{position:absolute;right:0;top:0}
.w3-display-bottomleft{position:absolute;left:0;bottom:0}.w3-display-bottomright{position:absolute;right:0;bottom:0}
.w3-display-middle{position:absolute;top:50%;left:50%;-webkit-transform:translate(-50%,-50%);transform:translate(-50%,-50%);-ms-transform:translate(-50%,-50%)}
.w3-display-left{position:absolute;top:50%;left:0%;-webkit-transform:translate(0%,-50%);transform:translate(0%,-50%);-ms-transform:translate(-0%,-50%)}
.w3-display-right{position:absolute;top:50%;right:0%;-webkit-transform:translate(0%,-50%);transform:translate(0%,-50%);-ms-transform:translate(0%,-50%)}
.w3-display-topmiddle{position:absolute;left:50%;top:0;-webkit-transform:translate(-50%,0%);transform:translate(-50%,0%);-ms-transform:translate(-50%,0%)}
.w3-display-bottommiddle{position:absolute;left:50%;bottom:0;-webkit-transform:translate(-50%,0%);transform:translate(-50%,0%);-ms-transform:translate(-50%,0%)}
.w3-display-container:hover .w3-display-hover{display:block}.w3-display-container:hover span.w3-display-hover{display:inline-block}.w3-display-hover{display:none}
.w3-display-position{position:absolute}
.w3-circle{border-radius:50%}
.w3-round-small{border-radius:2px}.w3-round,.w3-round-medium{border-radius:4px}.w3-round-large{border-radius:8px}.w3-round-xlarge{border-radius:16px}.w3-round-xxlarge{border-radius:32px}
.w3-row-padding,.w3-row-padding>.w3-half,.w3-row-padding>.w3-third,.w3-row-padding>.w3-twothird,.w3-row-padding>.w3-threequarter,.w3-row-padding>.w3-quarter,.w3-row-padding>.w3-col{padding:0 16px}
.w3-container,.w3-panel{padding:0.01em 16px}.w3-panel{margin-top:16px;margin-bottom:16px}
.w3-code,.w3-codespan{font-family:Consolas,"courier new";font-size:16px}
.w3-code{width:auto;background-color:#fff;padding:8px 12px;border-left:4px solid #4CAF50;word-wrap:break-word}
.w3-codespan{color:crimson;background-color:#f1f1f1;padding-left:4px;padding-right:4px;font-size:110%}
.w3-card,.w3-card-2{box-shadow:0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12)}
.w3-card-4,.w3-hover-shadow:hover{box-shadow:0 4px 10px 0 rgba(0,0,0,0.2),0 4px 20px 0 rgba(0,0,0,0.19)}
.w3-spin{-webkit-animation:w3-spin 2s infinite linear;animation:w3-spin 2s infinite linear}@-webkit-keyframes w3-spin{0%{-webkit-transform:rotate(0deg);transform:rotate(0deg)}100%{-webkit-transform:rotate(359deg);transform:rotate(359deg)}}@keyframes w3-spin{0%{-webkit-transform:rotate(0deg);transform:rotate(0deg)}100%{-webkit-transform:rotate(359deg);transform:rotate(359deg)}}
.w3-animate-fading{-webkit-animation:fading 10s infinite;animation:fading 10s infinite}@-webkit-keyframes fading{0%{opacity:0}50%{opacity:1}100%{opacity:0}}@keyframes fading{0%{opacity:0}50%{opacity:1}100%{opacity:0}}
.w3-animate-opacity{-webkit-animation:opac 0.8s;animation:opac 0.8s}@-webkit-keyframes opac{from{opacity:0} to{opacity:1}}@keyframes opac{from{opacity:0} to{opacity:1}}
.w3-animate-top{position:relative;-webkit-animation:animatetop 0.4s;animation:animatetop 0.4s}@-webkit-keyframes animatetop{from{top:-300px;opacity:0} to{top:0;opacity:1}}@keyframes animatetop{from{top:-300px;opacity:0} to{top:0;opacity:1}}
.w3-animate-left{position:relative;-webkit-animation:animateleft 0.4s;animation:animateleft 0.4s}@-webkit-keyframes animateleft{from{left:-300px;opacity:0} to{left:0;opacity:1}}@keyframes animateleft{from{left:-300px;opacity:0} to{left:0;opacity:1}}
.w3-animate-right{position:relative;-webkit-animation:animateright 0.4s;animation:animateright 0.4s}@-webkit-keyframes animateright{from{right:-300px;opacity:0} to{right:0;opacity:1}}@keyframes animateright{from{right:-300px;opacity:0} to{right:0;opacity:1}}
.w3-animate-bottom{position:relative;-webkit-animation:animatebottom 0.4s;animation:animatebottom 0.4s}@-webkit-keyframes animatebottom{from{bottom:-300px;opacity:0} to{bottom:0;opacity:1}}@keyframes animatebottom{from{bottom:-300px;opacity:0} to{bottom:0;opacity:1}}
.w3-animate-zoom {-webkit-animation:animatezoom 0.6s;animation:animatezoom 0.6s}@-webkit-keyframes animatezoom{from{-webkit-transform:scale(0);transform:scale(0)} to{-webkit-transform:scale(1);transform:scale(1)}}@keyframes animatezoom{from{-webkit-transform:scale(0);transform:scale(0)} to{-webkit-transform:scale(1);transform:scale(1)}}
.w3-animate-input{transition:width 0.4s ease-in-out}.w3-animate-input:focus{width:100%!important}
.w3-opacity,.w3-hover-opacity:hover{opacity:0.60}.w3-opacity-off,.w3-hover-opacity-off:hover{opacity:1}
.w3-opacity-max{opacity:0.25}.w3-opacity-min{opacity:0.75}
.w3-greyscale-max,.w3-grayscale-max,.w3-hover-greyscale:hover,.w3-hover-grayscale:hover{-webkit-filter:grayscale(100%);filter:grayscale(100%)}
.w3-greyscale,.w3-grayscale{-webkit-filter:grayscale(75%);filter:grayscale(75%)}.w3-greyscale-min,.w3-grayscale-min{-webkit-filter:grayscale(50%);filter:grayscale(50%)}
.w3-sepia{-webkit-filter:sepia(75%);filter:sepia(75%)}.w3-sepia-max,.w3-hover-sepia:hover{-webkit-filter:sepia(100%);filter:sepia(100%)}.w3-sepia-min{-webkit-filter:sepia(50%);filter:sepia(50%)}
.w3-tiny{font-size:10px!important}.w3-small{font-size:12px!important}.w3-medium{font-size:15px!important}.w3-large{font-size:18px!important}
.w3-xlarge{font-size:24px!important}.w3-xxlarge{font-size:36px!important}.w3-xxxlarge{font-size:48px!important}.w3-jumbo{font-size:64px!important}
.w3-left-align{text-align:left!important}.w3-right-align{text-align:right!important}.w3-justify{text-align:justify!important}.w3-center{text-align:center!important}
.w3-border-0{border:0!important}.w3-border{border:1px solid #e4e4e4!important}
.w3-border-top{border-top:1px solid #e4e4e4!important}.w3-border-bottom{border-bottom:1px solid #e4e4e4!important}
.w3-border-left{border-left:1px solid #e4e4e4!important}.w3-border-right{border-right:1px solid #e4e4e4!important}
.w3-topbar{border-top:6px solid #e4e4e4!important}.w3-bottombar{border-bottom:6px solid #e4e4e4!important}
.w3-leftbar{border-left:6px solid #e4e4e4!important}.w3-rightbar{border-right:6px solid #e4e4e4!important}
.w3-section,.w3-code{margin-top:16px!important;margin-bottom:16px!important}
.w3-margin{margin:16px!important}.w3-margin-top{margin-top:16px!important}.w3-margin-bottom{margin-bottom:16px!important}
.w3-margin-left{margin-left:16px!important}.w3-margin-right{margin-right:16px!important}
.w3-padding-small{padding:4px 8px!important}.w3-padding{padding:8px 16px!important}.w3-padding-large{padding:12px 24px!important}
.w3-padding-16{padding-top:16px!important;padding-bottom:16px!important}.w3-padding-24{padding-top:24px!important;padding-bottom:24px!important}
.w3-padding-32{padding-top:32px!important;padding-bottom:32px!important}.w3-padding-48{padding-top:48px!important;padding-bottom:48px!important}
.w3-padding-64{padding-top:64px!important;padding-bottom:64px!important}
.w3-left{float:left!important}.w3-right{float:right!important}
.w3-button:hover{color:#000!important;background-color:#e4e4e4!important}
.w3-transparent,.w3-hover-none:hover{background-color:transparent!important}
.w3-hover-none:hover{box-shadow:none!important}
/* Colors */
.w3-amber,.w3-hover-amber:hover{color:#000!important;background-color:#ffc107!important}
.w3-aqua,.w3-hover-aqua:hover{color:#000!important;background-color:#00ffff!important}
.w3-blue,.w3-hover-blue:hover{color:#fff!important;background-color:#2196F3!important}
.w3-light-blue,.w3-hover-light-blue:hover{color:#000!important;background-color:#87CEEB!important}
.w3-brown,.w3-hover-brown:hover{color:#fff!important;background-color:#795548!important}
.w3-cyan,.w3-hover-cyan:hover{color:#000!important;background-color:#00bcd4!important}
.w3-blue-grey,.w3-hover-blue-grey:hover,.w3-blue-gray,.w3-hover-blue-gray:hover{color:#fff!important;background-color:#607d8b!important}
.w3-green,.w3-hover-green:hover{color:#fff!important;background-color:#4CAF50!important}
.w3-light-green,.w3-hover-light-green:hover{color:#000!important;background-color:#8bc34a!important}
.w3-indigo,.w3-hover-indigo:hover{color:#fff!important;background-color:#3f51b5!important}
.w3-khaki,.w3-hover-khaki:hover{color:#000!important;background-color:#f0e68c!important}
.w3-lime,.w3-hover-lime:hover{color:#000!important;background-color:#cddc39!important}
.w3-orange,.w3-hover-orange:hover{color:#000!important;background-color:#ff9800!important}
.w3-deep-orange,.w3-hover-deep-orange:hover{color:#fff!important;background-color:#ff5722!important}
.w3-pink,.w3-hover-pink:hover{color:#fff!important;background-color:#e91e63!important}
.w3-purple,.w3-hover-purple:hover{color:#fff!important;background-color:#9c27b0!important}
.w3-deep-purple,.w3-hover-deep-purple:hover{color:#fff!important;background-color:#673ab7!important}
.w3-red,.w3-hover-red:hover{color:#fff!important;background-color:#f44336!important}
.w3-sand,.w3-hover-sand:hover{color:#000!important;background-color:#fdf5e6!important}
.w3-teal,.w3-hover-teal:hover{color:#fff!important;background-color:#009688!important}
.w3-yellow,.w3-hover-yellow:hover{color:#000!important;background-color:#ffeb3b!important}
.w3-white,.w3-hover-white:hover{color:#000!important;background-color:#fff!important}
.w3-black,.w3-hover-black:hover{color:#fff!important;background-color:#000!important}
.w3-grey,.w3-hover-grey:hover,.w3-gray,.w3-hover-gray:hover{color:#000!important;background-color:#9e9e9e!important}
.w3-light-grey,.w3-hover-light-grey:hover,.w3-light-gray,.w3-hover-light-gray:hover{color:#000!important;background-color:#f1f1f1!important}
.w3-dark-grey,.w3-hover-dark-grey:hover,.w3-dark-gray,.w3-hover-dark-gray:hover{color:#fff!important;background-color:#616161!important}
.w3-pale-red,.w3-hover-pale-red:hover{color:#000!important;background-color:#ffdddd!important}
.w3-pale-green,.w3-hover-pale-green:hover{color:#000!important;background-color:#ddffdd!important}
.w3-pale-yellow,.w3-hover-pale-yellow:hover{color:#000!important;background-color:#ffffcc!important}
.w3-pale-blue,.w3-hover-pale-blue:hover{color:#000!important;background-color:#ddffff!important}
.w3-text-amber,.w3-hover-text-amber:hover{color:#ffc107!important}
.w3-text-aqua,.w3-hover-text-aqua:hover{color:#00ffff!important}
.w3-text-blue,.w3-hover-text-blue:hover{color:#2196F3!important}
.w3-text-light-blue,.w3-hover-text-light-blue:hover{color:#87CEEB!important}
.w3-text-brown,.w3-hover-text-brown:hover{color:#795548!important}
.w3-text-cyan,.w3-hover-text-cyan:hover{color:#00bcd4!important}
.w3-text-blue-grey,.w3-hover-text-blue-grey:hover,.w3-text-blue-gray,.w3-hover-text-blue-gray:hover{color:#607d8b!important}
.w3-text-green,.w3-hover-text-green:hover{color:#4CAF50!important}
.w3-text-light-green,.w3-hover-text-light-green:hover{color:#8bc34a!important}
.w3-text-indigo,.w3-hover-text-indigo:hover{color:#3f51b5!important}
.w3-text-khaki,.w3-hover-text-khaki:hover{color:#b4aa50!important}
.w3-text-lime,.w3-hover-text-lime:hover{color:#cddc39!important}
.w3-text-orange,.w3-hover-text-orange:hover{color:#ff9800!important}
.w3-text-deep-orange,.w3-hover-text-deep-orange:hover{color:#ff5722!important}
.w3-text-pink,.w3-hover-text-pink:hover{color:#e91e63!important}
.w3-text-purple,.w3-hover-text-purple:hover{color:#9c27b0!important}
.w3-text-deep-purple,.w3-hover-text-deep-purple:hover{color:#673ab7!important}
.w3-text-red,.w3-hover-text-red:hover{color:#f44336!important}
.w3-text-sand,.w3-hover-text-sand:hover{color:#fdf5e6!important}
.w3-text-teal,.w3-hover-text-teal:hover{color:#009688!important}
.w3-text-yellow,.w3-hover-text-yellow:hover{color:#d2be0e!important}
.w3-text-white,.w3-hover-text-white:hover{color:#fff!important}
.w3-text-black,.w3-hover-text-black:hover{color:#000!important}
.w3-text-grey,.w3-hover-text-grey:hover,.w3-text-gray,.w3-hover-text-gray:hover{color:#757575!important}
.w3-text-light-grey,.w3-hover-text-light-grey:hover,.w3-text-light-gray,.w3-hover-text-light-gray:hover{color:#f1f1f1!important}
.w3-text-dark-grey,.w3-hover-text-dark-grey:hover,.w3-text-dark-gray,.w3-hover-text-dark-gray:hover{color:#3a3a3a!important}
.w3-border-amber,.w3-hover-border-amber:hover{border-color:#ffc107!important}
.w3-border-aqua,.w3-hover-border-aqua:hover{border-color:#00ffff!important}
.w3-border-blue,.w3-hover-border-blue:hover{border-color:#2196F3!important}
.w3-border-light-blue,.w3-hover-border-light-blue:hover{border-color:#87CEEB!important}
.w3-border-brown,.w3-hover-border-brown:hover{border-color:#795548!important}
.w3-border-cyan,.w3-hover-border-cyan:hover{border-color:#00bcd4!important}
.w3-border-blue-grey,.w3-hover-border-blue-grey:hover,.w3-border-blue-gray,.w3-hover-border-blue-gray:hover{border-color:#607d8b!important}
.w3-border-green,.w3-hover-border-green:hover{border-color:#4CAF50!important}
.w3-border-light-green,.w3-hover-border-light-green:hover{border-color:#8bc34a!important}
.w3-border-indigo,.w3-hover-border-indigo:hover{border-color:#3f51b5!important}
.w3-border-khaki,.w3-hover-border-khaki:hover{border-color:#f0e68c!important}
.w3-border-lime,.w3-hover-border-lime:hover{border-color:#cddc39!important}
.w3-border-orange,.w3-hover-border-orange:hover{border-color:#ff9800!important}
.w3-border-deep-orange,.w3-hover-border-deep-orange:hover{border-color:#ff5722!important}
.w3-border-pink,.w3-hover-border-pink:hover{border-color:#e91e63!important}
.w3-border-purple,.w3-hover-border-purple:hover{border-color:#9c27b0!important}
.w3-border-deep-purple,.w3-hover-border-deep-purple:hover{border-color:#673ab7!important}
.w3-border-red,.w3-hover-border-red:hover{border-color:#f44336!important}
.w3-border-sand,.w3-hover-border-sand:hover{border-color:#fdf5e6!important}
.w3-border-teal,.w3-hover-border-teal:hover{border-color:#009688!important}
.w3-border-yellow,.w3-hover-border-yellow:hover{border-color:#ffeb3b!important}
.w3-border-white,.w3-hover-border-white:hover{border-color:#fff!important}
.w3-border-black,.w3-hover-border-black:hover{border-color:#000!important}
.w3-border-grey,.w3-hover-border-grey:hover,.w3-border-gray,.w3-hover-border-gray:hover{border-color:#9e9e9e!important}
.w3-border-light-grey,.w3-hover-border-light-grey:hover,.w3-border-light-gray,.w3-hover-border-light-gray:hover{border-color:#f1f1f1!important}
.w3-border-dark-grey,.w3-hover-border-dark-grey:hover,.w3-border-dark-gray,.w3-hover-border-dark-gray:hover{border-color:#616161!important}
.w3-border-pale-red,.w3-hover-border-pale-red:hover{border-color:#ffe7e7!important}.w3-border-pale-green,.w3-hover-border-pale-green:hover{border-color:#e7ffe7!important}
.w3-border-pale-yellow,.w3-hover-border-pale-yellow:hover{border-color:#ffffcc!important}.w3-border-pale-blue,.w3-hover-border-pale-blue:hover{border-color:#e7ffff!important}
